import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="200px" height="25px" viewBox="0 0 200 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-53.000000, -40.000000)">
            <g transform="translate(53.000000, 40.000000)">
              <g>
                <polygon fill={color} points="14.9006932 9.37238936e-05 14.9006932 10.7830277 2.44385053 10.7830277 2.44385053 9.37238936e-05 0.000468619468 9.37238936e-05 0.000468619468 23.9062473 2.44385053 23.9062473 2.44385053 12.8608864 14.9006932 12.8608864 14.9006932 23.9062473 17.3759413 23.9062473 17.3759413 9.37238936e-05"></polygon>
                <g>
                  <g transform="translate(20.641375, 6.298527)">
                    <path d="M12.2206585,9.6066991 C12.2206585,13.9292451 9.47829736,16.035221 6.46413694,16.035221 C3.88672987,16.035221 2.31123122,14.6996555 2.31123122,12.8917216 C2.31123122,10.9825659 4.087299,10.0115863 6.93181917,9.54015513 C10.3817957,8.93938498 11.7885913,8.10243061 12.2206585,6.9974259 L12.2206585,9.6066991 Z M14.4644085,6.09392756 C14.4644085,3.41436145 13.3959561,0 7.76877354,0 C3.51745773,0 1.37586676,2.94667922 0.940050653,5.45754233 L3.28127352,5.45754233 C3.8201859,3.2447212 5.49409465,1.9400846 7.80438862,1.9400846 C10.4136618,1.9400846 12.1541145,3.01322318 12.1541145,4.98611114 C12.1541145,6.52599471 10.7510678,7.09583599 8.00495776,7.53165209 C4.75461312,8.06681553 0,8.80535981 0,12.8917216 C0,15.4700659 1.97663692,17.9453139 6.13047988,17.9453139 C10.0481386,17.9453139 11.5533444,15.6350199 12.1231856,14.1963582 L12.1541145,14.1963582 L12.1541145,14.9667686 C12.1541145,15.968677 12.1541145,16.7072213 12.2562736,17.6116569 L14.7671367,17.6116569 C14.5974964,16.6406773 14.4644085,15.3360407 14.4644085,14.0632702 L14.4644085,6.09392756 Z" id="Fill-2" fill={color}></path>
                  </g>
                  <path d="M41.8045118,10.0135545 L41.8045118,6.59919307 L39.4632889,6.59919307 L39.4632889,23.9081218 L41.8045118,23.9081218 L41.8045118,15.3023938 C41.8045118,11.184166 43.3097175,9.71082634 45.2188732,9.07444111 C45.8243296,8.80732801 46.5591249,8.67330284 47.1598951,8.67330284 L47.1598951,6.49609679 C44.35099,6.49609679 42.6770812,7.70326054 41.8045118,10.0135545" id="Fill-5" fill={color}></path>
                  <path d="M53.1728452,10.0135545 L53.1728452,6.59919307 L50.8269361,6.59919307 L50.8269361,23.9081218 L53.1728452,23.9081218 L53.1728452,15.3023938 C53.1728452,11.184166 54.6771137,9.71082634 56.5872066,9.07444111 C57.1879768,8.80732801 57.9274583,8.67330284 58.5282285,8.67330284 L58.5282285,6.49609679 C55.7193234,6.49609679 54.0407285,7.70326054 53.1728452,10.0135545" id="Fill-7" fill={color}></path>
                  <g id="Clip-10"></g>
                  <polygon fill={color} points="62.2101716 23.9061536 64.5560807 23.9061536 64.5560807 6.59722487 62.2101716 6.59722487"></polygon>
                  <polygon  fill={color} points="62.2457867 3.35156644 64.7210348 3.35156644 64.7210348 0.00374895575 62.2457867 0.00374895575"></polygon>
                  <path d="M75.9589045,14.5348889 C75.1884941,14.2987047 74.3515397,14.0981355 73.6176816,13.8985036 C71.9072206,13.4617503 70.4648098,12.660411 70.4648098,11.0174311 C70.4648098,9.1748194 72.3439739,8.27132106 74.1828367,8.27132106 C76.1285447,8.27132106 78.1004955,9.1748194 78.3357424,11.7878416 L80.6779025,11.7878416 C80.2102203,8.00420796 77.5353404,6.29468414 74.2493807,6.29468414 C71.3383165,6.29468414 68.123587,7.97234184 68.123587,11.1205274 C68.123587,13.8628886 70.4648098,15.2378181 72.9138152,15.8751406 C73.7507696,16.0747724 74.4190209,16.2397265 75.3225193,16.5115258 C77.098587,16.979208 78.5719266,17.5799782 78.5719266,19.222958 C78.5719266,21.431093 76.5287458,22.2361812 74.4190209,22.2361812 C71.8725427,22.2361812 70.265178,21.0618208 69.8996548,18.7505896 L67.5537457,18.7505896 C68.057043,22.8378886 71.4714045,24.1781403 74.3515397,24.1781403 C77.2635411,24.1781403 80.9140868,22.7038634 80.9140868,19.120799 C80.9140868,16.3418855 78.5363116,15.202203 75.9589045,14.5348889" id="Fill-12" fill={color}></path>
                  <polygon fill={color} points="141.820559 -9.37238936e-05 137.333059 17.8833624 136.496105 21.3333389 136.43331 21.3333389 135.592606 17.8833624 131.438763 -9.37238936e-05 127.956921 -9.37238936e-05 123.737471 17.9499064 122.904266 21.2977239 122.868651 21.2977239 122.031696 17.9499064 117.642606 -9.37238936e-05 115.038019 -9.37238936e-05 119.654858 17.9499064 121.163813 23.9069971 124.542559 23.9069971 125.980284 17.9142913 129.698311 2.51076939 129.733926 2.51076939 134.723786 23.9069971 138.108156 23.9069971 144.433581 -9.37238936e-05"></polygon>
                  <path d="M152.468624,22.2340256 C148.78715,22.2340256 146.307216,19.1223923 146.307216,15.2712775 C146.307216,11.3845476 148.78715,8.27291437 152.468624,8.27291437 C156.150099,8.27291437 158.630033,11.3845476 158.630033,15.2712775 C158.630033,19.1223923 156.150099,22.2340256 152.468624,22.2340256 M152.468624,6.29627745 C147.179785,6.29627745 143.965055,10.3826392 143.965055,15.2712775 C143.965055,20.1589786 147.179785,24.2106625 152.468624,24.2106625 C157.726535,24.2106625 160.972193,20.1589786 160.972193,15.2712775 C160.972193,10.3826392 157.726535,6.29627745 152.468624,6.29627745" id="Fill-14" fill={color}></path>
                  <path d="M171.856162,22.2340256 C168.174687,22.2340256 165.694753,19.1223923 165.694753,15.2712775 C165.694753,11.3845476 168.174687,8.27291437 171.856162,8.27291437 C175.537636,8.27291437 178.01757,11.3845476 178.01757,15.2712775 C178.01757,19.1223923 175.537636,22.2340256 171.856162,22.2340256 M171.856162,6.29627745 C166.567322,6.29627745 163.352593,10.3826392 163.352593,15.2712775 C163.352593,20.1589786 166.567322,24.2106625 171.856162,24.2106625 C177.114072,24.2106625 180.35973,20.1589786 180.35973,15.2712775 C180.35973,10.3826392 177.114072,6.29627745 171.856162,6.29627745" id="Fill-15" fill={color}></path>
                  <path d="M190.943126,22.2032841 C187.092011,22.2032841 185.084446,19.2209898 185.084446,15.2358499 C185.084446,11.2516472 187.159493,8.27310182 190.943126,8.27310182 C194.860785,8.27310182 196.837422,11.4522163 196.837422,15.2358499 C196.837422,18.6858264 195.092283,22.2032841 190.943126,22.2032841 L190.943126,22.2032841 Z M196.735263,0.00196820177 L196.735263,10.1475797 L196.699648,10.1475797 C195.862693,8.23748674 193.823261,6.2964649 190.573854,6.2964649 C185.516513,6.2964649 182.738537,10.1785086 182.738537,15.2358499 C182.738537,20.2931912 185.516513,24.2108499 190.573854,24.2108499 C193.787646,24.2108499 195.830827,22.3682382 196.699648,20.3606724 L196.735263,20.3606724 L196.735263,23.9081218 L199.076486,23.9081218 L199.076486,0.00196820177 L196.735263,0.00196820177 Z" id="Fill-16" fill={color}></path>
                  <path d="M102.050043,13.9888535 C101.288068,13.9888535 100.631063,14.092887 100.078092,14.3018913 C99.7847367,14.4134227 99.5073139,14.5577575 99.244887,14.7367701 L99.1558493,14.6467952 C99.3423599,14.3862428 99.4941926,14.112569 99.5991634,13.8238994 C99.7959835,13.2831125 99.8943936,12.6195474 99.8943936,11.8332039 L99.8943936,6.59872446 L97.3610368,6.59872446 L97.3610368,11.8332039 C97.3610368,12.5951792 97.4650703,13.2521837 97.6740746,13.8051546 C97.7846688,14.0985104 97.9299408,14.3759331 98.1080162,14.6374228 L98.0180413,14.7273978 C97.7574888,14.5408872 97.4847523,14.3890545 97.1951455,14.2840837 C96.6552959,14.0872636 95.9917307,13.9888535 95.2053872,13.9888535 L89.9709078,13.9888535 L89.9709078,16.5222103 L95.2053872,16.5222103 C95.9673625,16.5222103 96.624367,16.4181768 97.1764007,16.2091725 C97.4706937,16.0985783 97.7471792,15.9533063 98.0096061,15.7752309 L98.0995811,15.8642686 C97.9130705,16.1257582 97.7612378,16.3984948 97.6553298,16.6881016 C97.4594469,17.2279512 97.3610368,17.8915164 97.3610368,18.6778599 L97.3610368,23.9123393 L99.8943936,23.9123393 L99.8943936,18.6778599 C99.8943936,17.9158846 99.7903601,17.2588801 99.5813558,16.7068464 C99.4698244,16.4125534 99.3254896,16.1360679 99.146477,15.873641 L99.2364519,15.783666 C99.4970043,15.9701766 99.7706781,16.1220093 100.059348,16.2279173 C100.600135,16.4238002 101.2637,16.5222103 102.050043,16.5222103 L107.284523,16.5222103 L107.284523,13.9888535 L102.050043,13.9888535 Z" id="Fill-17" fill={color}></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class LogoFooter extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="108px" height="50px" viewBox="0 0 108 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-52.000000, -61.000000)">
            <g transform="translate(52.986000, 61.500000)">
              <g transform="translate(0.000000, 0.000000)">
                <polygon fill={color} points="14.0151272 0.000125827113 14.0151272 10.1417912 2.29936467 10.1417912 2.29936467 0.000125827113 0.000503308453 0.000125827113 0.000503308453 22.485431 2.29936467 22.485431 2.29936467 12.0933697 14.0151272 12.0933697 14.0151272 22.485431 16.3441871 22.485431 16.3441871 0.000125827113"></polygon>
                <g>
                  <path d="M25.9826698,21.0063333 C23.5579813,21.0063333 22.0769962,19.7468039 22.0769962,18.0456213 C22.0769962,16.2500684 23.746722,15.3378218 26.4230647,14.8961686 C29.6681459,14.3299466 30.9905889,13.5422689 31.399527,12.5029369 L31.399527,14.9590822 C31.399527,19.0220397 28.8175546,21.0063333 25.9826698,21.0063333 M31.4309838,22.4860601 L33.7927587,22.4860601 C33.6354748,21.5738135 33.5096477,20.3444826 33.5096477,19.1478668 L33.5096477,11.6523457 C33.5096477,9.13328685 32.5017725,5.92092064 27.2107424,5.92092064 C23.2106985,5.92092064 21.1949481,8.69163368 20.78601,11.0534086 L22.990501,11.0534086 C23.4950677,8.97474468 25.0691649,7.74667206 27.2421992,7.74667206 C29.6996027,7.74667206 31.3366134,8.75454724 31.3366134,10.6130137 C31.3366134,12.0612838 30.0141705,12.5973073 27.4309398,13.0062454 C24.3758575,13.5108121 19.9039619,14.2028612 19.9039619,18.0456213 C19.9039619,20.471568 21.7624284,22.8018862 25.668102,22.8018862 C29.3523199,22.8018862 30.7703914,20.6288519 31.3051567,19.2736939 L31.3366134,19.2736939 L31.3366134,19.9984581 C31.3366134,20.9434197 31.3366134,21.6367271 31.4309838,22.4860601" id="Fill-2" fill={color}></path>
                  <path d="M39.8088046,9.41627202 L39.8088046,6.20390582 L37.6043136,6.20390582 L37.6043136,22.4871926 L39.8088046,22.4871926 L39.8088046,14.3927344 C39.8088046,10.5185175 41.2256179,9.13316102 43.0211708,8.53422396 C43.5886511,8.28256973 44.2807002,8.15674262 44.8481805,8.15674262 L44.8481805,6.10953549 C42.2020363,6.10953549 40.6279391,7.24323778 39.8088046,9.41627202" id="Fill-4" fill={color}></path>
                  <path d="M50.0131319,9.41627202 L50.0131319,6.20390582 L47.8086408,6.20390582 L47.8086408,22.4871926 L50.0131319,22.4871926 L50.0131319,14.3927344 C50.0131319,10.5185175 51.4299452,9.13316102 53.2254981,8.53422396 C53.7929783,8.28256973 54.4850275,8.15674262 55.0525077,8.15674262 L55.0525077,6.10953549 C52.4063636,6.10953549 50.8322664,7.24323778 50.0131319,9.41627202" id="Fill-6" fill={color}></path>
                  <g></g>
                  <path d="M57.5721957,3.14945265 L59.9025138,3.14945265 L59.9025138,0 L57.5721957,0 L57.5721957,3.14945265 Z M57.5407389,22.4865634 L59.7452299,22.4865634 L59.7452299,6.20327669 L57.5407389,6.20327669 L57.5407389,22.4865634 Z" fill={color}></path>
                  <path d="M73.1932543,11.0854945 L75.3977454,11.0854945 C74.9573505,7.52710374 72.4370334,5.92029151 69.3504943,5.92029151 C66.611238,5.92029151 63.5876125,7.49564697 63.5876125,10.4551007 C63.5876125,13.0383313 65.7921035,14.3293175 68.0909649,14.9282545 C68.8786426,15.1169952 69.5077782,15.2742791 70.3583695,15.5259333 C72.0280953,15.9675865 73.4134518,16.5338085 73.4134518,18.0777072 C73.4134518,20.1563711 71.4920718,20.9113338 69.5077782,20.9113338 C67.1145465,20.9113338 65.6033629,19.8090883 65.25608,17.636054 L63.051589,17.636054 C63.5246989,21.4788141 66.7370651,22.7383435 69.4448646,22.7383435 C72.1853791,22.7383435 75.6179428,21.352987 75.6179428,17.9833369 C75.6179428,15.3686494 73.381995,14.2978607 70.9573065,13.6674669 C70.2325424,13.4472694 69.4448646,13.2585288 68.7528155,13.0697881 C67.1460033,12.6595917 65.7921035,11.904629 65.7921035,10.3607303 C65.7921035,8.62934926 67.5561997,7.77875797 69.2875807,7.77875797 C71.1145904,7.77875797 72.9730569,8.62934926 73.1932543,11.0854945" id="Fill-10" fill={color}></path>
                  <polygon fill={color} points="52.044359 25.947564 47.8241176 42.7668742 47.0364398 46.0094389 46.9747846 46.0094389 46.1858486 42.7668742 42.2814332 25.947564 39.0048952 25.947564 35.0375663 42.8285295 34.2498886 45.9779822 34.2184318 45.9779822 33.4320124 42.8285295 29.304883 25.947564 26.8487378 25.947564 31.1948063 42.8285295 32.6128778 48.4353857 35.792529 48.4353857 37.147687 42.7970727 40.6431642 28.3093389 40.674621 28.3093389 45.3679723 48.4353857 48.5488817 48.4353857 54.5005042 25.947564"></polygon>
                  <path d="M62.5010954,46.8605335 C59.0370749,46.8605335 56.7054985,43.9312783 56.7054985,40.309974 C56.7054985,36.6559546 59.0370749,33.7266994 62.5010954,33.7266994 C65.9651158,33.7266994 68.2966922,36.6559546 68.2966922,40.309974 C68.2966922,43.9312783 65.9651158,46.8605335 62.5010954,46.8605335 M62.5010954,48.719 C67.4461009,48.719 70.5011832,44.9076967 70.5011832,40.309974 C70.5011832,35.710993 67.4461009,31.8694913 62.5010954,31.8694913 C57.524633,31.8694913 54.5010075,35.710993 54.5010075,40.309974 C54.5010075,44.9076967 57.524633,48.719 62.5010954,48.719" id="Fill-12" fill={color}></path>
                  <path d="M80.7365898,46.8605335 C77.2725693,46.8605335 74.9409929,43.9312783 74.9409929,40.309974 C74.9409929,36.6559546 77.2725693,33.7266994 80.7365898,33.7266994 C84.2006102,33.7266994 86.5321866,36.6559546 86.5321866,40.309974 C86.5321866,43.9312783 84.2006102,46.8605335 80.7365898,46.8605335 M80.7365898,48.719 C85.6815953,48.719 88.7366776,44.9076967 88.7366776,40.309974 C88.7366776,35.710993 85.6815953,31.8694913 80.7365898,31.8694913 C75.7601274,31.8694913 72.7365019,35.710993 72.7365019,40.309974 C72.7365019,44.9076967 75.7601274,48.719 80.7365898,48.719" id="Fill-13" fill={color}></path>
                  <path d="M98.6885957,46.8289509 C95.0660331,46.8289509 93.1773681,44.0255228 93.1773681,40.2783914 C93.1773681,36.5300017 95.1302049,33.7265736 98.6885957,33.7265736 C102.372814,33.7265736 104.23128,36.7187424 104.23128,40.2783914 C104.23128,43.5222144 102.594269,46.8289509 98.6885957,46.8289509 L98.6885957,46.8289509 Z M104.13691,25.9479415 L104.13691,35.4906698 L104.105453,35.4906698 C103.317775,33.6951168 101.397653,31.8693654 98.3425711,31.8693654 C93.5863062,31.8693654 90.9728771,35.5221265 90.9728771,40.2783914 C90.9728771,45.033398 93.5863062,48.7188742 98.3425711,48.7188742 C101.366197,48.7188742 103.286318,46.9862348 104.105453,45.0963116 L104.13691,45.0963116 L104.13691,48.4357632 L106.341401,48.4357632 L106.341401,25.9479415 L104.13691,25.9479415 Z" id="Fill-14" fill={color}></path>
                  <path d="M11.7735172,38.5984736 C11.0512695,38.5984736 10.4284253,38.697877 9.90372626,38.8954256 C9.62564834,39.0011204 9.36392794,39.1382719 9.11479026,39.3068803 L9.02922782,39.2225761 C9.20664405,38.9746967 9.35008696,38.7154928 9.45074865,38.442448 C9.63697278,37.9303317 9.73008484,37.3011961 9.73008484,36.5550413 L9.73008484,31.5936782 L7.32804525,31.5936782 L7.32804525,36.5550413 C7.32804525,37.2772889 7.42744867,37.9001331 7.62499724,38.4235739 C7.73069201,38.7029101 7.86784357,38.9646305 8.0364519,39.2137682 L7.95214773,39.2993306 C7.70426832,39.1219144 7.44506447,38.9772132 7.17201963,38.8778098 C6.65990328,38.6915857 6.03076771,38.5984736 5.28461293,38.5984736 L0.323249854,38.5984736 L0.323249854,41.0005132 L5.28461293,41.0005132 C6.00686056,41.0005132 6.62970477,40.9011098 7.15440384,40.7035612 C7.43248176,40.5978665 7.69420215,40.4607149 7.94333984,40.2921066 L8.02890227,40.3764107 C7.85148604,40.6242901 7.70804313,40.883494 7.60738144,41.1565388 C7.42115732,41.6686552 7.32804525,42.2977908 7.32804525,43.0439455 L7.32804525,48.0053086 L9.73008484,48.0053086 L9.73008484,43.0439455 C9.73008484,42.3216979 9.63068143,41.6988537 9.43313286,41.1741546 C9.32743808,40.8960767 9.19028653,40.6343563 9.0216782,40.3852186 L9.10598236,40.2996562 C9.35386178,40.4770724 9.61306563,40.6205153 9.88611047,40.721177 C10.3982268,40.9074012 11.0273624,41.0005132 11.7735172,41.0005132 L16.7348802,41.0005132 L16.7348802,38.5984736 L11.7735172,38.5984736 Z" id="Fill-15" fill={color}></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class UpArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="12px" height="17px" viewBox="0 0 12 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1362.000000, -1358.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(1326.000000, 1317.756440)">
              <g transform="translate(36.014428, 40.985572)">
                <g transform="translate(6.000000, 8.014428) rotate(-90.000000) translate(-6.000000, -8.014428) translate(-2.014428, 2.014428)">
                  <polygon points="0 5.10420913 13.8796973 5.10420913 13.8796973 6.89488823 0 6.89488823"></polygon>
                  <polygon points="10.0296942 12 8.77546664 10.7457724 13.5220987 6 8.77546664 1.25422757 10.0296942 7.63534581e-16 16.028856 6.00002149"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class ThemeToggle extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1280.000000, -40.000000)" fill={color}>
            <g transform="translate(1280.279309, 40.000000)">
              <path d="M9.999,3.2895497e-15 L9.9989976,1.48148154 C5.29481041,1.48202323 1.48148148,5.29568627 1.48148148,10 C1.48148148,14.7046479 5.29535213,18.5185185 10,18.5185185 L9.999,18.518 L9.99899707,20 C4.57021593,19.9994672 0.152059155,15.6729971 0.00384179753,10.2799048 L0,10 C0,4.4771525 4.4771525,1.03949771e-14 10,1.03949771e-14 L9.999,3.2895497e-15 Z"></path>
              <path d="M19.8148148,0 C19.8768844,0 19.9388218,0.000565500398 20.0006223,0.00169163011 L19.9998148,19.997 L19.8148148,20 C14.3855749,20 9.9668831,15.6733292 9.81865661,10.2799048 L9.81481481,10 C9.81481481,4.4771525 14.2919673,0 19.8148148,0 Z" transform="translate(14.907727, 10.000000) scale(-1, 1) translate(-14.907727, -10.000000) "></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class YouTube extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="23px" height="16px" viewBox="0 0 23 16" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1355.000000, -67.000000)" fill={color}>
            <g id="/i/youtube" transform="translate(1355.000000, 67.000000)">
              <path d="M22.6349206,11.8191726 L22.6349206,3.84398525 C22.6349206,3.84398525 22.6349206,-2.61778903e-15 18.7342296,-2.61778903e-15 L3.89936015,-2.61778903e-15 C3.89936015,-2.61778903e-15 0,-2.61778903e-15 0,3.84398525 L0,11.8191726 C0,11.8191726 0,15.6631579 3.89936015,15.6631579 L18.7342296,15.6631579 C18.7342296,15.6631579 22.6349206,15.6631579 22.6349206,11.8191726 M15.7118928,7.84272664 L8.30177769,12.1313079 L8.30177769,3.55283386 L15.7118928,7.84272664"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1313.000000, -66.000000)" fill={color}>
            <g id="/i/instagram" transform="translate(1313.000000, 66.000000)">
              <path d="M5.27741655,-3.55271368e-15 L13.008104,-3.55271368e-15 C15.9111902,-3.55271368e-15 18.2851777,2.34461691 18.2851777,5.20890427 L18.2851777,12.918678 C18.2851777,15.7836511 15.9111902,18.1275823 13.008104,18.1275823 L5.27741655,18.1275823 C2.37433032,18.1275823 0,15.7836511 0,12.918678 L0,5.20890427 C0,2.34461691 2.37430175,-3.55271368e-15 5.27741655,-3.55271368e-15 Z M9.09333313,4.16956335 C11.9144218,4.16956335 14.2036974,6.45883902 14.2036974,9.27992766 C14.2036974,12.1013591 11.9144218,14.390292 9.09333313,14.390292 C6.2715588,14.390292 3.98262598,12.1013591 3.98262598,9.27992766 C3.98262598,6.45883902 6.2715588,4.16956335 9.09333313,4.16956335 Z M9.09333313,5.89562698 C10.9614783,5.89562698 12.4776338,7.41146821 12.4776338,9.27992766 C12.4776338,11.1483871 10.9614497,12.6645712 9.09333313,12.6645712 C7.22453083,12.6645712 5.70868961,11.1484157 5.70868961,9.27992766 C5.70868961,7.41146821 7.22453083,5.89562698 9.09333313,5.89562698 Z M14.0373309,3.46126985 C14.4956032,3.46126985 14.8671351,3.8328018 14.8671351,4.29073122 C14.8671351,4.74900349 14.4956032,5.12053544 14.0373309,5.12053544 C13.5794015,5.12053544 13.2078695,4.74900349 13.2078695,4.29073122 C13.2078695,3.8328018 13.5794015,3.46126985 14.0373309,3.46126985 L14.0373309,3.46126985 Z M5.9091123,1.48075654 L12.3770653,1.48075654 C14.8057083,1.48075654 16.7919643,3.45721283 16.7919643,5.87239909 L16.7919643,12.3727226 C16.7919643,14.7878517 14.8057369,16.7639937 12.3770653,16.7639937 L5.9091123,16.7639937 C3.48046929,16.7639937 1.49389901,14.7878517 1.49389901,12.372694 L1.49389901,5.87234195 C1.49389901,3.45721283 3.48046929,1.48075654 5.9091123,1.48075654 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="17px" height="13px" viewBox="0 0 17 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1365.000000, -163.000000)">
            <g transform="translate(1365.000000, 163.243560)">
              <polygon fill={color} fillRule="nonzero" points="0 5.10420913 13.8796973 5.10420913 13.8796973 6.89488823 0 6.89488823"></polygon>
              <polygon fill={color} fillRule="nonzero" points="10.0296942 12 8.77546664 10.7457724 13.5220987 6 8.77546664 1.25422757 10.0296942 7.63534581e-16 16.028856 6.00002149"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Plus extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-429.000000, -1448.000000)" fill={color}>
            <g transform="translate(429.000000, 1448.000000)">
              <polygon points="15.2283871 27.3135484 15.2283871 14.9574194 27.8554839 14.9574194 27.8554839 12.4103226 15.2283871 12.4103226 15.2283871 0 12.6812903 0 12.6812903 12.4103226 5.13424428e-14 12.4103226 5.13424428e-14 14.9574194 12.6812903 14.9574194 12.6812903 27.3135484"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1303.000000, -194.000000)">
            <g transform="translate(1301.982667, 191.882353)">
              <g transform="translate(36.882353, 38.000000) scale(1, -1) rotate(90.000000) translate(-36.882353, -38.000000)">
                <g transform="translate(36.133414, 38.000000) rotate(90.000000) translate(-36.133414, -38.000000) translate(0.000000, 2.235294)">
                  <polygon fill={color} fillRule="nonzero" points="32.7803808 0.100804851 39.4833504 0.100804851 39.4833504 67.1393494 32.7803808 67.1393494"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="5.3203134 30.5541201 40.8719059 66.1057126 36.1305383 70.8470802 0.578945785 35.2954877"></polygon>
                  <polygon fill={color} fillRule="nonzero" points="66.9463675 30.5541201 71.6877351 35.2954877 36.1361426 70.8470802 31.394775 66.1057126"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


class Facebook extends Component {

  render() {

    const { color } = this.props

    return (
            
      <svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-840.000000, -375.000000)" fill={color}>
            <g transform="translate(840.000000, 375.000000)">
              <path d="M5.86793103,5.43016393 L5.86793103,3.96819672 C5.86793103,3.28196721 6.33862069,3.10295082 6.71517241,3.10295082 L8.84896552,3.10295082 L8.84896552,-5.32907052e-15 L5.89931034,-5.32907052e-15 C2.63586207,-5.32907052e-15 1.91413793,2.29737705 1.91413793,3.78918033 L1.91413793,5.43016393 L3.8191672e-14,5.43016393 L3.8191672e-14,7.60819672 L3.8191672e-14,9.04032787 L1.91413793,9.04032787 L1.91413793,18.0806557 L5.71103448,18.0806557 L5.71103448,9.04032787 L8.53517241,9.04032787 L8.66068966,7.60819672 L8.88034483,5.40032787 L5.86793103,5.40032787 L5.86793103,5.43016393 Z" id="Path"></path>
            </g>
          </g>
        </g>
      </svg>

    )
  }

}

class Close extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1360.000000, -67.000000)" fill={color}>
            <g transform="translate(1357.500000, 64.970563)">
              <g transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) ">
                <polygon points="13.0529032 23.4116129 13.0529032 12.8206452 23.876129 12.8206452 23.876129 10.6374194 13.0529032 10.6374194 13.0529032 0 10.8696774 0 10.8696774 10.6374194 4.40078082e-14 10.6374194 4.40078082e-14 12.8206452 10.8696774 12.8206452 10.8696774 23.4116129"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Phone extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M0.32686762,6.15611621 C0.32686762,6.15611621 -0.206548317,4.47547589 0.089454016,2.85566492 C0.32686762,1.29524448 1.15628589,0.695064327 1.39294962,0.515413945 C1.63036323,0.395202061 1.98572389,0.155553647 1.98572389,0.155553647 C1.98572389,0.155553647 2.4004429,-0.264416589 2.8745005,0.275765531 C3.3485581,0.755825724 5.54088951,3.03586684 5.54088951,3.03586684 C5.54088951,3.03586684 5.95560852,3.51592703 5.6002439,3.99600721 C5.24488323,4.41597744 3.99997636,5.67664752 3.99997636,5.67664752 C4.53339229,7.71714813 8.14795571,11.3772999 10.1627449,11.91745 C10.1627449,11.91745 11.4068822,10.7168899 11.8809398,10.3570296 C12.2956588,9.99716927 12.7697164,10.4171355 12.7697164,10.4171355 C12.7697164,10.4171355 15.0213075,12.5777361 15.5547037,13.0579362 C16.0287613,13.5981063 15.6140581,13.9579666 15.6140581,13.9579666 C15.6140581,13.9579666 15.4359949,14.3779368 15.2586974,14.5582467 C15.1399886,14.7986664 14.4886375,15.6385869 12.9477085,15.9383373 C11.4067993,16.1787571 9.68852551,15.6385869 9.68852551,15.6385869 C6.13255082,14.1983864 1.74737492,9.81763479 0.325182381,6.15668373 L0.32686762,6.15611621 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}

class Play extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="60px" height="61px" viewBox="0 0 60 61" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1307.000000, -1009.000000)" fill={color} fillRule="nonzero">
            <g transform="translate(1307.000000, 1009.743560)">
              <path d="M30,0 C13.4505,0 0,13.4505 0,30 C0,46.5495 13.4505,60 30,60 C46.5495,60 60,46.5495 60,30 C60,13.4505 46.5495,0 30,0 Z M30,3 C44.9295,3 57,15.0705 57,30 C57,44.9295 44.9295,57 30,57 C15.0705,57 3,44.9295 3,30 C3,15.0705 15.0705,3 30,3 Z M21.75,16.5 L21.75,43.5 L44.25,30 L21.75,16.5 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class HWPlus extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-54.000000, -8018.000000)" fill={color}>
            <g transform="translate(54.000000, 8018.929128)">
              <path d="M15.348671,9.39046175 C14.3804471,9.39046175 13.5456071,9.52265469 12.84296,9.78823147 C12.4701998,9.92995182 12.1176853,10.1133546 11.7842256,10.3408217 L11.6710875,10.2264927 C11.9080821,9.89541493 12.1010123,9.54766416 12.2343961,9.18085855 C12.4844909,8.4936935 12.6095382,7.65051697 12.6095382,6.65132897 L12.6095382,0 L9.39046175,0 L9.39046175,6.65132897 C9.39046175,7.61955286 9.52265469,8.4543929 9.78823147,9.15704 C9.92876089,9.52980025 10.1133546,9.88231473 10.3396308,10.2157744 L10.2253018,10.3289125 C9.894224,10.0919179 9.54766416,9.89898771 9.17966762,9.76560385 C8.4936935,9.51550912 7.65051697,9.39046175 6.65132897,9.39046175 L0,9.39046175 L0,12.6095382 L6.65132897,12.6095382 C7.61955286,12.6095382 8.4543929,12.4773453 9.15584908,12.2117685 C9.52980025,12.0712391 9.8811238,11.8866454 10.2145834,11.6603692 L10.3289125,11.7735073 C10.0919179,12.105776 9.89898771,12.4523358 9.76441293,12.8203324 C9.51550912,13.5063065 9.39046175,14.349483 9.39046175,15.348671 L9.39046175,22 L12.6095382,22 L12.6095382,15.348671 C12.6095382,14.3804471 12.4773453,13.5456071 12.2117685,12.8441509 C12.0700482,12.4701998 11.8866454,12.1188762 11.6591783,11.7854166 L11.7735073,11.6710875 C12.1045851,11.9080821 12.4523358,12.1010123 12.8191414,12.2355871 C13.5063065,12.4844909 14.349483,12.6095382 15.348671,12.6095382 L22,12.6095382 L22,9.39046175 L15.348671,9.39046175 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  } 
}

class Star extends Component {
  render() {
      let { color } = this.props
      return (
        <svg width="11px" height="10px" viewBox="0 0 11 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="/i/5star" transform="translate(-9.000000, -5.000000)" fill={color} fill-rule="nonzero">
                  <g transform="translate(9.000000, 3.000000)">
                      <g transform="translate(0.000000, 2.000000)">
                          <polygon points="5.25798224 0 6.88208058 3.29157023 10.5158199 3.81914918 7.88690107 6.38180287 8.50719097 10 5.25790995 8.29156421 2.00862893 10 2.62891883 6.38180287 0 3.81914918 3.63373932 3.29157023"></polygon>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
  }
}

export { Logo, LogoFooter, UpArrow, ThemeToggle, YouTube, Instagram, RightArrow, Plus, DownArrow, Close, Facebook, Phone, Play, HWPlus, Star }
