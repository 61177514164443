import React, { useState } from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import '../assets/scss/main.scss'

import Header from '../components/header'
import ScrollBack from '../components/scroll-back'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()

    const [theme, setTheme] = useState('Dark')

    const toggleSwitch = () => {
        if (theme === 'Dark') {
        setTheme('Light')
        document.documentElement.style.setProperty('--theme-dark', '#FFFFFF')
        document.documentElement.style.setProperty('--theme-light', '#000000')
        document.documentElement.style.setProperty('--theme-border', '#999999')
        document.documentElement.style.setProperty('--theme-dark-border', 'rgba(255, 255, 255, 0.15)')
        document.documentElement.style.setProperty('--theme-light-border', 'rgba(0, 0, 0, 0.15')
        } else {
        setTheme('Dark')
        document.documentElement.style.setProperty('--theme-dark', '#000000')
        document.documentElement.style.setProperty('--theme-light', '#FFFFFF')
        document.documentElement.style.setProperty('--theme-border', '#999999')
        document.documentElement.style.setProperty('--theme-dark-border', 'rgba(0, 0, 0, 0.15)')
        document.documentElement.style.setProperty('--theme-light-border', 'rgba(255, 255, 255, 0.15)')
        }
    }

    const updatedChildren = React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
            theme: theme
        })
    )

    return (
        <AtollonProvider {...props} globalData={globalData} theme={theme}>
            <Header toggleSwitch={toggleSwitch} theme={theme} />
            <main>{ updatedChildren }</main>
            <ScrollBack theme={theme} />
            <Footer theme={theme} form={globalData.subscribeForm} />
        </AtollonProvider>
    )
}

export default Layout

