import React from 'react';
import { UpArrow } from './icons';

const ScrollBack = ({ theme }) => {
  const handleScrollToTop = () => {
    if (window) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <section className='scroll-back'>
      <div className='scroll-back__inner'>
        <div className='scroll-back__content'>
          <div
            className='scroll-back__text'
            role='button'
            tabIndex='-1'
            onKeyDown={handleScrollToTop}
            onClick={handleScrollToTop}
          >
            <p>Back to top</p>
          </div>
          <div
            className='scroll-back__image'
            role='button'
            tabIndex='-1'
            onKeyDown={handleScrollToTop}
            onClick={handleScrollToTop}
          >
            <UpArrow color={theme === 'Dark' ? '#000000' : '#FFFFFF'} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollBack;