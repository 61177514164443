import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query {
      subscribeForm: datoCmsForm(title: {eq: "Newsletter"}) {
        ...FormProps
      }
      ...GlobalData
    }
  `)
}

export default useLayoutQuery