module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"rootMargin":"0px 0px 0px 0px"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Harris & Wood","short_name":"Harris & Wood","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52c57bcc2993acc7bb28db742a0ca282"},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.harriswood.com.au","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-plugin-atollon/gatsby-browser.js'),
      options: {"plugins":[],"disableLiveReload":true,"datoCmsFullAccessApiKey":"fd6ef9f40e981c9a9954be48f65bb1","datoCmsUrl":"https://harris-wood.admin.datocms.com","draftsUrl":"https://harriswood-drafts.netlify.app","productionUrl":"https://www.harriswood.com.au","site":{"name":"Harris & Wood","defaultMetaDescription":"The finest real estate on the shipwreck coast.","facebookPageUrl":"https://www.facebook.com/harrisandwood/","twitterAccount":"@atollon","primaryColor":"#000000","lightColor":"#FFFFFF","accentColor":"#000000","darkColor":"#000000","icon":"src/assets/images/favicon.svg"},"email":{"host":"ventraip.email","port":465,"username":"noreply@mailer.atollon.com.au","password":"dvr-mka6FJF!nat!jaf","sender":"noreply@mailer.atollon.com.au"},"connectors":[{"id":"rex","type":"real-estate","data":{"provider":"rex","email":"nick@atollon.com.au","password":"BingoBango2018!","accountId":630,"disabledForTypes":["leased"]}}],"locales":[],"models":[{"name":"Pages","apiKey":"page","apiKeyMultiple":"pages","type":"page","hasBlocks":true,"isDefaultPageType":true},{"name":"Header","apiKey":"header_menu_item","apiKeyMultiple":"header_menu_items","type":"menu"},{"name":"Footer","apiKey":"footer_menu_item","apiKeyMultiple":"footer_menu_items","type":"menu"},{"name":"Site Options","apiKey":"site_option_page","apiKeyMultiple":"site_option_pages","type":"site-options"},{"name":"Properties","apiKey":"property","apiKeyMultiple":"properties","type":"property","connectors":["rex"]},{"name":"Agents","apiKey":"agent","apiKeyMultiple":"agents","type":"agent","connectors":["rex"],"permalink":true},{"name":"Inspections","apiKey":"inspection","apiKeyMultiple":"inspections","type":"inspection","timezone":"Australia/Melbourne","connectors":["rex"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
