exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-land-js": () => import("./../../../src/pages/land.js" /* webpackChunkName: "component---src-pages-land-js" */),
  "component---src-pages-lease-js": () => import("./../../../src/pages/lease.js" /* webpackChunkName: "component---src-pages-lease-js" */),
  "component---src-pages-sold-js": () => import("./../../../src/pages/sold.js" /* webpackChunkName: "component---src-pages-sold-js" */),
  "component---src-templates-agent-js": () => import("./../../../src/templates/agent.js" /* webpackChunkName: "component---src-templates-agent-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */)
}

