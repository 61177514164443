import React, { Component } from 'react'
import { Link } from "../../plugins/gatsby-plugin-atollon"
import { LogoFooter } from './icons'
import FormComponent from './form'

class Footer extends Component {

  render() {
    
    let { theme } = this.props

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__cols'>
            <div className='footer__logo'>
              <Link to='/' title='Harris+Wood' className='footer__logo-text'>
                <LogoFooter color={theme === 'Dark' ? '#FFFFFF' : '#000000'} />
              </Link>
            </div>
            <div className='footer__col'>
              <ul>
                <li>
                  <Link to='tel:+61355612228'>03 5561 2228</Link>
                </li>
                <li className='address'>
                  <Link to='mailto:sales@harriswood.com.au'>sales@harriswood.com.au</Link>
                </li>
                <li>
                  <Link to='/contact'>192 Koroit Street<br />Warrnambool Victoria 3280</Link>
                </li>
              </ul>
            </div>
            <div className='footer__col'>
              <h4>Listings</h4>
              <ul>
                <li><Link to='/buy'>For Sale</Link></li>
                <li><Link to='/lease'>For Lease</Link></li>
                <li><Link to='/sold'>Sold</Link></li>
                <li><Link to='/appraisals'>Appraisals</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <h4>About</h4>
              <ul>
                <li><Link to='/about'>About Us</Link></li>
                <li><Link to='/agents'>Team</Link></li>
                <li><Link to='/offer-to-purchase/'>Offer to Purchase</Link></li>
                <li><Link to='/register-to-bid/'>Register to Bid</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <h4>Connect</h4>
              <ul>
                <li><Link to='/contact'>Contact Us</Link></li>
                <li><Link to='https://www.instagram.com/harriswoodrealestate/'>Instagram</Link></li>
                <li><Link to='https://www.facebook.com/harrisandwood/'>Facebook</Link></li>
                <li><Link to='https://au.linkedin.com/company/harris-wood-real-estate/'>LinkedIn</Link></li>
              </ul>
            </div>
            <div className='footer__col'>
              <div className='newsletter'>
                <span>Join our mailing list</span>
                <FormComponent form={this.props.form} theme={theme} />
              </div>
            </div>
          </div>
        </div>
        <div className='footer__inner'>
          <div className='footer__credits'>
            <ul>
              <li><Link to='https://www.consumer.vic.gov.au/duediligencechecklist'>Due Diligence Checklist</Link></li>
            </ul>
            <p className='footer__copyright'>© Harris + Wood { new Date().getFullYear() }. Design by <Link to='https://atollon.com.au'>Atollon</Link></p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
