import React, { useState } from 'react'
import {Form} from "../../plugins/gatsby-plugin-atollon";
import Select from 'react-select'

const FormComponent = (props) => {

  const [selectedOption, setSelectedOption] = useState("");

  return (
    <Form
      schema={props.form}
      renderField={(field) => {
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          return (
            <div className={`form__text${field.label === 'Property' ? ' form__text--hidden' : ''}`}>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={field.label === 'Property' ? 'hidden' : field.type}
                defaultValue={field.label === 'Property' ? props.property : undefined}
                id={field.id}
                autoComplete={field.autoComplete}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "select") {
          let options = []
          field.options.map((option) => (
            options.push({ value: option.value, label: option.label })
          ))
          const handleTypeSelect = (e) => {
            setSelectedOption(e.value);
            let event = {
              target: {
                name: field.name,
                value: e.value
              }
            }
            field.onChange(event)
          };
          const queryString = typeof window !== 'undefined' ? window.location.search : null;
          const urlParams = new URLSearchParams(queryString);
          const agent = urlParams.get('agent');
          if (agent && field.name === 'agent') {
            setSelectedOption(agent);
          }
          return (
            <div className='form__select'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <Select
                classNamePrefix='react-select'
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={handleTypeSelect}
                id={field.id}
                name={field.name}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
                defaultValue={agent && field.name === 'agent' ? { value: agent, label: agent } : undefined}
                options={options}
                placeholder={field.placeholder}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "radio") {
          return (
            <div className='form__radio'>
              <label>
                {field.label}
              </label>
              {field.options.map((option) => (
                <div className='form__radio-group'>
                  <input
                    id={option.id}
                    type="radio"
                    value={option.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "checkboxes") {
          return (
              <div className='form__checkboxes'>
                  <label className='form__checkboxes-label'>
                    {field.label}
                  </label>
                  {field.options.map((option) => (
                      <div className="form__checkbox">
                          <input
                              type="checkbox"
                              ref={field.ref}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              name={field.name}
                              id={option.id}
                              aria-required={field.required}
                              disabled={field.disabled}
                              aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                              aria-invalid={field?.invalid}
                              value={option.value}
                          />
                          <label htmlFor={option.id}>
                              {option.label}
                          </label>
                      </div>
                  ))}
                  {field.hasErrors && (
                      <div role="alert">{field.errors.join(", ")}</div>
                  )}
              </div>
          )
        }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div>{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => ( <div className='form__submit'><button className='btn' type="submit">{children}</button></div>) }
      renderConfirmationMessage={({ message }) => (
        <div className='form__confirmation'>{message}</div>
      )}
      renderLoadingSpinner={() => <div>Loading</div>}
    />
  )
}

export default FormComponent
